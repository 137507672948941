import useSWR from 'swr';
import * as customerApi from '../../api/customer';

export const useCustomerDetails = (customerId?: number) => {
  const { data, isValidating, mutate } = useSWR(
    () => {
      if (typeof window === 'undefined' || !customerId) {
        return null;
      }
      return String(customerId);
    },
    () => {
      if (!customerId) {
        return Promise.resolve(undefined);
      }
      return customerApi.fetchCustomer(customerId);
    }
  );

  return {
    details: data,
    loading: isValidating,
    refetch: () => {
      return mutate(() => {
        if (!customerId) {
          return Promise.resolve(undefined);
        }
        return customerApi.fetchCustomer(customerId);
      });
    },
  };
};
