import StorageManager from '../utils/storage-manager';

const FOODAID_PET_KEY = 'pd:foodaid:pet-data';
const REFERAL_TOKEN_STORE_KEY = 'pd:referal-token';
const OPTILE_PRESET_ID_KEY = 'pd:optile-preset-id';

export const foodaidPetStore = new StorageManager<any>(FOODAID_PET_KEY);
export const referalTokenStore = new StorageManager<string>(
  REFERAL_TOKEN_STORE_KEY
);
export const optilePresetIdStore = new StorageManager<string>(
  OPTILE_PRESET_ID_KEY
);
