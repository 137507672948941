import cn from 'classnames';
import AmountInput from 'components/amount-input/input';
import Button from 'components/button';
import InlineIntervalSelector from 'components/inline-interval-selector';
import ModalSlideUp from 'components/modal-slide-up';
import SubscriptionConfiguratorProduct, {
  PriceDisplayType,
} from 'components/subscription-configurator-product';
import { SubscriptionInterval } from 'constants/order';
import { useCart } from 'contexts/cart';
import { useTrackingContext } from 'contexts/tracking';
import { CollectionProduct } from 'models/collection/collection';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Gtm, gtm } from 'tracking/gtm';

export const PCMode = {
  FUBE: 'fube',
  FUNNEL: 'funnel',
  ONE_TIME: 'onetime',
} as const;

export type PCMode = (typeof PCMode)[keyof typeof PCMode];

export interface PCConfig {
  /** Control display and behavior of the component */
  mode?: PCMode;
  hasExistingSubscription?: boolean;
  /** Callback after on add click, if not assigned fallback to default behavior of cart in action*/
  onAdd?: (arg?: {
    selectedVariantId: number;
    product: CollectionProduct;
    amount: number;
    interval: null | string;
  }) => void | Promise<void>;
  onAddText?: React.ReactNode;
  onClose?: () => void;
}

interface DisplayConfig {
  showOrderType: boolean;
  priceDisplayType: PriceDisplayType;
}

const getDisplayConfigByPCMode = (mode?: PCMode): DisplayConfig => {
  switch (mode) {
    case PCMode.FUNNEL:
      return {
        showOrderType: false,
        priceDisplayType: PriceDisplayType.SUBSCRIPTION,
      };

    case PCMode.ONE_TIME:
      return {
        showOrderType: false,
        priceDisplayType: PriceDisplayType.ONE_OFF,
      };
    case PCMode.FUBE:
    default:
      return {
        showOrderType: true,
        priceDisplayType: PriceDisplayType.BOTH,
      };
  }
};

interface Context {
  open: (product: CollectionProduct, options?: PCConfig) => void;
}

const ProductConfiguratorContext = React.createContext({} as Context);

/**
 * Product configurator.
 * Shows modal to let user choose variant, amount and interval depending on configuration passed.
 * Since this context is injected in _app level, we shouldn't put specific logic here.
 */
const ProductConfigurator: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [product, setProduct] = React.useState<null | CollectionProduct>(null);
  const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState<PCConfig>({});
  const [displayConfig, setDisplayConfig] = React.useState<DisplayConfig>({
    showOrderType: true,
    priceDisplayType: PriceDisplayType.BOTH,
  });
  const intl = useIntl();
  const { setTrackedList } = useTrackingContext();

  const open = React.useCallback((p: CollectionProduct, options?: PCConfig) => {
    setProduct(p);
    if (options) {
      console.log({ options });
      setConfig(options);
      setDisplayConfig(getDisplayConfigByPCMode(options.mode));
    }
  }, []);

  const close = React.useCallback(() => {
    config.onClose && config.onClose();
    setProduct(null);
    setInterval(null);
    setVariant(null);
    setAmount(1);
    setConfig({});
    setDisplayConfig({
      showOrderType: true,
      priceDisplayType: PriceDisplayType.BOTH,
    });
  }, [config]);

  const cheapestVariantId = React.useMemo(() => {
    if (product) {
      const cheapestVariant = [...product.variants].sort(
        (a, b) => Number(a.priceV2.amount) - Number(b.priceV2.amount)
      )[0];

      return cheapestVariant.id;
    }

    return null;
  }, [product]);

  const defaultPrice = React.useMemo(() => {
    if (product) {
      const cheapestPrice = product.variants.filter(
        (variant) => variant.id === cheapestVariantId
      )[0].priceV2.amount;

      return +cheapestPrice;
    }

    return null;
  }, [product, cheapestVariantId]);

  const [selectedVariantId, setVariant] = React.useState<number | null>(
    cheapestVariantId
  );

  const [selectedVariantPrice, setVariantPrice] = React.useState<number | null>(
    0
  );

  const [amount, setAmount] = React.useState<number>(1);
  const [interval, setInterval] = React.useState<string | null>(null);
  const { addProductToCart, addSubItemToCart } = useCart();

  React.useEffect(() => {
    setVariant(cheapestVariantId);
    setVariantPrice(defaultPrice);

    // avoid interval for one off items and choose interval when available
    setInterval(
      product?.tags && product.tags.indexOf('SubscriptionEnabled') > -1
        ? '4week'
        : null
    );
  }, [cheapestVariantId, product, defaultPrice]);

  const onClick = useCallback(async () => {
    if (!selectedVariantId) return;

    setLoading(true);
    try {
      if (config.onAdd) {
        if (!product) {
          throw new Error('wrong usage of product configurator ');
        }
        await config.onAdd({
          selectedVariantId,
          product,
          amount,
          interval,
        });
      } else {
        if (interval) {
          await addSubItemToCart({
            variantId: selectedVariantId,
            quantity: amount,
            interval: interval as SubscriptionInterval,
          });
        } else {
          await addProductToCart({
            variantId: selectedVariantId,
            quantity: amount,
          });
        }

        if (product && selectedVariantPrice) {
          gtm({
            group: Gtm.GroupName.Product,
            name: Gtm.ProductEventName.AddToCart,
            data: {
              list: 'FuBe',
              item: {
                productId: product.id,
                variantTitle: product.variants.filter(
                  (variant) => variant.id === selectedVariantId
                )[0].title,
                variantId: selectedVariantId as number,
                productTitle: product.title,
                productType: product.productType,
                tags: product.tags.join(','),
                sku: product.variants.filter(
                  (variant) => variant.id === selectedVariantId
                )[0].sku,
                quantity: amount,
                ...(interval && { interval }),
                price:
                  selectedVariantPrice &&
                  Math.round(selectedVariantPrice * 100),
              },
            },
          });
          setTrackedList(product.id, 'FuBe');
        }
      }
      close();
    } finally {
      setLoading(false);
    }
  }, [
    addProductToCart,
    addSubItemToCart,
    amount,
    close,
    config,
    interval,
    product,
    selectedVariantId,
    selectedVariantPrice,
    setTrackedList,
  ]);

  return (
    <ProductConfiguratorContext.Provider value={{ open }}>
      {children}
      <ModalSlideUp
        open={product !== null}
        onClose={close}
        title={intl.formatMessage({ id: 'funnel:configurator:modal-title' })}
      >
        <h3 className="text-lg font-black text-brand-primary">
          <FormattedMessage id="common:product:variant" />
        </h3>

        {product?.variants.map((variant) => {
          return (
            <SubscriptionConfiguratorProduct
              key={variant.id}
              product={product}
              variantId={variant.id}
              active={selectedVariantId === variant.id}
              setVariant={setVariant}
              setVariantPrice={setVariantPrice}
              priceDisplayType={displayConfig.priceDisplayType}
            />
          );
        })}

        <h3 className="mt-6 text-lg font-black text-brand-primary">
          <FormattedMessage id="common:product:quantity" />
        </h3>

        <AmountInput
          value={amount}
          onChange={(a) => setAmount(a)}
          minValue={1}
        />

        {displayConfig.showOrderType && (
          <button
            className={cn(
              'focus-default relative mt-6 flex h-10 w-full items-center justify-center rounded border px-3 py-3 text-center font-black leading-tight text-brand-primary shadow',
              !interval
                ? 'border-brand-primary ring-1 ring-brand-primary'
                : 'border-gray-110'
            )}
            onClick={() => {
              setInterval('');
            }}
          >
            <FormattedMessage id="pdp:buy-panel:order-type:one-off" />
          </button>
        )}

        {product?.tags &&
          product.tags.indexOf('SubscriptionEnabled') > -1 &&
          !config.hasExistingSubscription && (
            <InlineIntervalSelector
              interval={interval}
              setInterval={setInterval}
            />
          )}

        <div className="mt-6">
          <Button
            loading={loading}
            disabled={loading}
            onClick={onClick}
            className="w-full"
          >
            {config.onAddText ? (
              <>{config.onAddText}</>
            ) : (
              <FormattedMessage
                id={`funnel:configurator:${
                  config.hasExistingSubscription
                    ? `confirm-selection`
                    : `add-to-cart`
                }`}
              />
            )}
          </Button>
        </div>
      </ModalSlideUp>
    </ProductConfiguratorContext.Provider>
  );
};

export const useProductConfigurator = (): Context =>
  React.useContext(ProductConfiguratorContext);

export default ProductConfigurator;
