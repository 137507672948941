import { Customer } from 'interfaces/customer';

interface Props {
  customer?: Customer;
}

export interface CheckoutHookProps {
  checkout: {
    id: string;
  };
}

/**
 * Stub hook to provide checkout id for the sake of compatibility and traceability
 * Once we know checkout id can be safely removed, this hook is obsolete.
 */
export const useCheckout = ({ customer }: Props): CheckoutHookProps => {
  return {
    checkout: {
      id: `${customer?.id}-${new Number(new Date())}`,
    },
  };
};
